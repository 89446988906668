import { post } from "./request";
import storage from "@/utils/storage";

/// 获取社区列表
export function getCommunityList(params) {
  return new Promise((resolve, reject) => {
    if (params.currentPage == 1) {
      let storageData = storage.getData("storage-list-community");
      if (storageData) {
        resolve(storageData);
      }
    }
    post("/api/frontapi/community/queryPage", params)
      .then((res) => {
        let resdata = res.data;
        if (res.code == 200) {
          if (params.currentPage == 1) {
            storage.setData("storage-list-community", resdata);
          }
          resolve(resdata);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/// 获取社区内容详情
export function getCommunityDetial(options) {
  const params = { id: options.id };
  if (options.type == 1) {
    // 资讯
    return post("/api/frontapi/community/getArticleDetail", params);
  } else if (options.type == 2) {
    // 问答
    return post("/api/frontapi/community/getQestionDetail", params);
  } else if (options.type == 3) {
    // 视频
    return post("/api/frontapi/community/getVideoDetail", params);
  } else if (options.type == 4) {
    // 帖子
    return post("/api/frontapi/community/getPostingDetail", params);
  } else if (options.type == 5) {
    // 案例
    return post("/api/frontapi/community/getDiaryDetail", params);
  }
  return null;
}

/// =============================
/// ========== 通用 API ======
/// =============================

/// 获取OSS配置参数
export function getOSSConfigData() {
  return post("/frontapi/aliyunOss/getOSSToken");
}
