<template>
  <div class="page">
    <!-- <b-container>
      <b-row>
        <b-col class="px-0" offset-md="1" md="10" lg="7">
          <head-banner></head-banner>
        </b-col>
      </b-row>
    </b-container> -->
    <head-banner></head-banner>
    <b-container>
      <b-row>
        <!-- <b-col offset-md="1" md="11" lg="7"> -->
        <b-col cols="12" lg="8">
          <template v-if="!listData.length">
            <page-error
              :loading="isLoading"
              :loaderror="loaderror"
            ></page-error>
          </template>
          <template v-else>
            <div
              v-for="(item, index) in listData"
              :key="index"
              @click="onClickItem(item)"
            >
              <template v-if="item.type == 2">
                <question-item :question="item"></question-item>
              </template>
              <template v-else>
                <community-item :community="item"></community-item>
              </template>
            </div>
          </template>
          <page-list-footer
            :listData="listData"
            :isLoading="isLoading"
            :canLoadMore="canLoadMore"
            :pageNum="currentPage"
            @loadmore="fetchListData"
          ></page-list-footer>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CommunityItem from "./components/CommunityItem";
import QuestionItem from "./components/QuestionItem";
import HeadBanner from "@/components/HeadLogo";
import PageError from "@/components/PageError";
import PageListFooter from "@/components/PageListFooter";
import { getCommunityList } from "@/utils/api/community";
import { mapState } from "vuex";

export default {
  name: "Package",
  components: {
    CommunityItem,
    QuestionItem,
    HeadBanner,
    PageError,
    PageListFooter,
  },
  data() {
    return {
      loadStatus: null,
      isScroll: true,
      isLoading: false,
      loaderror: false,
      canLoadMore: true,
      currentPage: 1,
      totalPage: 1,
      listData: [],
    };
  },
  computed: {
    ...mapState({ isMobile: (state) => state.app.isMobile }),
  },
  methods: {
    fetchListData() {
      if (this.isLoading || !this.canLoadMore) return;
      this.isLoading = true;

      const params = {
        currentPage: this.currentPage,
        pageSize: 20,
      };
      getCommunityList(params)
        .then((res) => {
          let list = res.data || [];
          if (this.currentPage == 1) {
            this.listData = [];
          }
          this.listData = this.listData.concat(list);
          this.canLoadMore = list.length >= params.pageSize;
          this.isLoading = false;
          this.totalPage = res.totalPage || 1;
          // this.loadStatus = this.listData.length > 0 ? null : "nodata";
          this.currentPage++;
          this.isScroll = true;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          // this.loadStatus = "error";
          this.loaderror = true;
          this.isScroll = true;
        });
    },

    fetchListDataByScroll() {
      if (!this.isMobile) return;
      // 滚动高度
      const scrollTopHeight =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 屏幕可用工作区高度
      const clientHeight =
        document.documentElement.clientHeight || window.screen.availHeight;
      // 网页可见区域高(包括边线的宽)
      const offsetHeight =
        document.documentElement.offsetHeight || document.body.offsetHeight;

      const isToBottom = scrollTopHeight + clientHeight + 60 >= offsetHeight;

      if (isToBottom && this.isScroll && this.canLoadMore && !this.isLoading) {
        this.isScroll = false;
        this.fetchListData();
      }
    },
    onClickItem(item) {
      this.$router.push({
        name: "CommunityDetail",
        query: { id: item.id, type: item.type },
      });
    },
  },
  created() {
    this.fetchListData();
  },
  mounted() {
    document.addEventListener("scroll", this.fetchListDataByScroll, false);
  },
  destroyed() {
    document.removeEventListener("scroll", this.fetchListDataByScroll, false);
  },
};
</script>

<style scoped lang="scss">
.page {
  margin-bottom: 60px;
}
</style>
