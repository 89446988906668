<template>
  <div class="page page-detail">
    <b-container>
      <b-row>
        <b-col cols="12" lg="8">
          <div v-if="isLoading" class="spinner-container">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
          <template v-else-if="detailData">
            <b-row class="page__main">
              <b-col>
                <div class="page__hd">
                  <div class="page__title">{{ contentTitle }}</div>
                  <div class="page__desc">
                    <div>
                      <b-icon icon="clock" class="mr-1"></b-icon>
                      <span>{{ postDateTime }}</span>
                    </div>
                    <div class="read-count ml-4">
                      <b-icon icon="eye" class="mr-1"></b-icon>
                      <span>{{ readCount }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="paramType == 3 && detailData.videoUrl">
                  <video
                    :poster="detailData.coverUrl"
                    width="100%"
                    controls
                    autoplay="autoplay"
                  >
                    <source :src="detailData.videoUrl" type="video/mp4" />
                  </video>
                </div>
                <div v-else class="page__bd">
                  <detail-body :html="contentBody" :cover="cover"></detail-body>
                </div>
                <div v-if="moreContentData" class="page__ft mt-5">
                  <div class="py-2">
                    <span class="mr-2">上一篇：</span>
                    <span
                      v-if="moreContentData.prev.id"
                      class="content__title"
                      @click="onClickMore(moreContentData.prev)"
                      >{{ moreContentData.prev.title }}</span
                    >
                    <span v-else class="content__title-nomore">没有了</span>
                  </div>
                  <div class="py-2">
                    <span class="mr-2">下一篇：</span>
                    <span
                      v-if="moreContentData.next.id"
                      class="content__title"
                      @click="onClickMore(moreContentData.next)"
                      >{{ moreContentData.next.title }}</span
                    >
                    <span v-else class="content__title-nomore">没有了</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <page-error
              :loaderror="loadError"
              :errtips="errorTips"
            ></page-error>
          </template>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DetailBody from "./../components/DetailBody";
import PageError from "@/components/PageError";
import { mapState, mapGetters } from "vuex";
import { getCommunityDetial } from "@/utils/api/community";

export default {
  name: "CommunityDetail",
  components: { DetailBody, PageError },
  data() {
    return {
      isLoading: false,
      errorTips: null,
      loadError: false,
      paramId: null,
      paramType: 0,
      detailData: null,
    };
  },
  computed: {
    ...mapState({
      userType: (state) => state.user.userType,
    }),
    ...mapGetters("user", { userInfo: "userInfo" }),
    contentTitle() {
      return this.detailData?.title || this.detailData?.question || "";
    },
    postDateTime() {
      return this.detailData?.createDatetime || "";
    },
    tags() {
      var tagStr = this.detailData?.showTags || "";
      if (tagStr.trim().length === 0) return [];
      return tagStr.split(",");
    },
    contentBody() {
      return this.detailData?.content || this.detailData?.answer || "";
    },
    readCount() {
      return this.detailData?.hitsCount || 0;
    },
    cover() {
      return this.detailData?.coverUrl || "";
    },
  },
  methods: {
    onClickMore(data) {
      this.$router.push({
        name: "CommunityDetail",
        query: { id: data.id, type: this.paramType },
      });
    },

    fetchDetail() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      const params = {
        id: this.paramId || "",
        type: this.paramType,
      };

      getCommunityDetial(params)
        .then((res) => {
          let info = res.data;
          this.detailData = info;
          this.isLoading = false;
          let title =
            (this.detailData?.title || "社区详情") + " - 台灣同性生育";
          document.getElementsByTagName("title")[0].innerText = title;

          if (info.prevOneId || info.nextOneId) {
            this.moreContentData = {
              prev: { title: info.prevOneTitle, id: info.prevOneId },
              next: { title: info.nextOneTitle, id: info.nextOneId },
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.loadError = true;
          this.errorTips = error.message;
        });
    },
  },
  watch: {
    $route(to) {
      if (to.name === "CommunityDetail") {
        this.paramId = to.query.id;
        this.paramType = to.query.type;
        this.fetchDetail();
      }
    },
  },
  created() {
    if (this.$route.params?.id) {
      this.paramId = this.$route.params.id;
      this.paramType = this.$route.params.type;
    } else if (this.$route.query?.id) {
      this.paramId = this.$route.query.id;
      this.paramType = this.$route.query.type;
    }
    this.fetchDetail();
  },
};
</script>

<style lang="scss" scoped>
.page {
  .spinner-container {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .page__main {
    background-color: #fff;
    padding: 1.5rem 1rem;
  }

  .page__hd {
    margin-bottom: 1.5rem;

    .page__title {
      font-size: 1.6em;
      font-weight: bold;
    }

    .page__desc {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 0.5em;
      font-size: 0.85em;
      color: #999;
    }

    .read-count {
      color: #aaa;
    }
  }

  .page__ft {
    font-size: 1rem;

    .content__title {
      cursor: pointer;
      color: #333;
    }

    .content__title:hover {
      color: var(--primary);
    }

    .content__title-nomore {
      color: #999;
    }
  }
}
</style>
