<template>
  <div class="content-body" :class="canToggleContentClass" ref="getheight">
    <div v-if="html" v-html="html"></div>
    <div v-else>{{ content }}</div>
    <template v-if="!hasImg">
      <template v-if="images">
        <b-img-lazy
          v-for="url in images"
          :key="url"
          :src="url"
          alt="image"
          class="body__img"
        ></b-img-lazy>
      </template>
      <template v-else-if="cover">
        <b-img-lazy :src="cover" alt="cover" class="cover__img"></b-img-lazy>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    cover: {
      type: String,
      default: null,
    },
    html: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    images: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      needToggle: false,
      isCollapsed: true,
      canToggleContentClass: "",
    };
  },
  computed: {
    hasImg() {
      return this.html.indexOf("<img") > -1 && this.html.indexOf('src="') > -1;
    },
  },
  methods: {
    toggleCollapsed: function () {
      this.isCollapsed = !this.isCollapsed;
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 页面渲染完成后的回调
      var offset = this.$refs.getheight.offsetHeight;
      this.needToggle = offset > 400; // 76
      this.canToggleContentClass = this.needToggle ? "can-toggle-content" : "";
    });
  },
};
</script>

<style lang="scss" scoped>
.content-body {
  color: #000;
  font-size: 1.05rem;
  white-space: pre-wrap;
  text-indent: 0em;
  line-height: 1.85rem;

  .cover__img {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }
}
</style>
