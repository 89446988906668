<template>
  <div class="community-item">
    <div class="community-item__hd">
      <div class="title-tag">{{ tag }}</div>
      <div class="ml-2 max-lines__1">{{ title }}</div>
    </div>
    <div class="community-item__bd">
      <div class="item-img">
        <b-img-lazy
          v-bind="mainProps"
          rounded
          :src="coverUrl"
          alt="image"
        ></b-img-lazy>
      </div>
      <div class="item-content ml-3">
        <div class="max-lines__2">{{ brief }}</div>
        <div class="read-count">
          <b-icon icon="eye" class="mr-2"></b-icon>
          <span>{{ readCount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imageUrl } from "@/utils/image";

export default {
  name: "CommunityItem",
  props: {
    community: {
      type: Object,
      default: null,
    },
  },
  computed: {
    type() {
      return this.community?.type || 0;
    },
    tag() {
      var type = this.community?.type || 0;
      if (type === 1) {
        return "資訊";
      } else if (type === 2) {
        return "問答";
      } else if (type === 3) {
        return "視頻";
      } else if (type === 4) {
        return "帖子";
      } else if (type === 5) {
        return "案例";
      }
      return "";
    },
    title() {
      return this.community?.title || "";
    },
    coverUrl() {
      return imageUrl(this.community?.coverUrl || "");
    },
    brief() {
      return this.community?.summary || "";
    },
    readCount() {
      return this.community?.hitsCount || 0;
    },
  },
  data() {
    return {
      mainProps: {
        blankColor: "#eee",
        // width: 128,
        // height: 72,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.community-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1.75rem 0rem;
  // border-bottom: 1px solid #eee;

  .community-item__hd {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 8px;

    .title-tag {
      min-width: 36px;
      display: inline-block;
      border: 1px solid #1fb9de;
      border-radius: 2px;
      padding: 0px 4px;
      font-size: 12px;
      font-weight: normal;
      color: #1fb9de;
    }
  }

  .community-item__bd {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .item-img {
      width: 128px;
      height: 72px;

      img.rounded {
        width: 128px;
        height: 72px;
        object-fit: cover;
      }
    }

    .item-content {
      min-height: 72px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .read-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #666;
    font-size: 0.75rem;
  }
}
</style>
