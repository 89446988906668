<template>
  <div class="question-item">
    <div class="question-item__hd max-lines__1">
      <b-badge variant="warning" class="mr-2">Q</b-badge>
      <span>{{ title }}</span>
    </div>
    <div class="max-lines__2">
      <b-badge variant="primary" class="mr-2">A</b-badge>
      <span>{{ answer }}</span>
    </div>
    <div class="read-count">
      <b-icon icon="eye" class="mr-2"></b-icon>
      <span>{{ readCount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionItem",
  props: {
    question: {
      type: Object,
      default: null,
    },
  },
  computed: {
    title() {
      return this.question?.question || "";
    },
    answer() {
      return this.question?.answer || "";
    },
    doctor() {
      var doctor = {
        doctorId: this.question?.doctorId,
        doctorName: this.question?.doctorName,
        doctorIcon: this.question?.doctorIcon,
        jobTitle: this.question?.doctorProfessionalTitle,
        dept: this.question?.doctorDepartName,
        orgName: this.question?.orgName,
      };
      return doctor;
    },
    readCount() {
      return this.question?.hitsCount || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.question-item {
  cursor: pointer;
  padding: 1.75rem 0rem;
  // border-bottom: 1px solid #eee;

  .question-item__hd {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 8px;

    .badge-warning {
      color: #fff;
    }
  }

  .read-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #666;
    font-size: 0.75rem;
  }
}
</style>
